import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AssetsContext from '#context/assets';
import ModalsContext from '#context/modals';
import Loader from '#components/loader.jsx';

function AssetsList({ type }) {
	const location = useLocation();
	const { list, api } = useContext(AssetsContext);
	const modals_context = useContext(ModalsContext);

	const [dragged_item, set_dragged_item] = useState(null);
	const [current_list, set_current_list] = useState();

	const drag_enter = (e, i) => {
		e.preventDefault();
		e.stopPropagation();

		const new_list = [...current_list];
		const content = new_list[dragged_item];

		new_list.splice(dragged_item, 1);
		new_list.splice(i, 0, content);

		set_dragged_item(i);
		set_current_list(new_list);
	};

	const drag_end = () => {
		api.update_indexes(current_list);
	};

	useEffect(() => {
		if (!list) return set_current_list(list);
		set_current_list(list.filter((el) => el.type === type).sort((a, b) => a.index - b.index));
	}, [list]);

	if (!current_list) return <Loader />;

	return (
		<>
			<div className="list_head">
				<p>Name</p>
				{type === 'equipment' ? <p>Qty</p> : <></>}
			</div>
			<ul className={`list ${type}`}>
				{current_list.map(({ name, _id, ids }, i) => (
					<li
						key={i}
						className={`${location.hash.includes(_id) ? 'active' : ''}`}
						draggable="true"
						onDragStart={() => set_dragged_item(i)}
						onDragEnter={(e) => drag_enter(e, i)}
						onDragOver={(e) => {
							e.preventDefault();
							e.stopPropagation();
						}}
						onDragEnd={drag_end}
					>
						<p>{name}</p>
						{ids ? <p>{ids.length}</p> : <></>}
						<div className="actions">
							<a className="icon edit" href={`#asset_${_id}`}></a>
							<a
								className="icon delete_red"
								onClick={() =>
									modals_context.show('asset_action', {
										action: 'delete',
										clb: () => api.delete(_id),
									})
								}
							></a>
						</div>
					</li>
				))}
			</ul>
		</>
	);
}

function Header({ header, label, link }) {
	return (
		<div className="header_block">
			<h1>{header}</h1>
			<a className="button approve" href={link}>
				{label}
			</a>
		</div>
	);
}

function RoomsView() {
	const { get_list } = useContext(AssetsContext);

	useEffect(() => {
		get_list();
	}, []);

	return (
		<section className="dashboard_list rooms_list assets_list">
			<Header header="People" label="+ Add Person" link="#asset_staff" />
			<AssetsList type="staff" />

			<Header header="Equipment" label="+ Add Equipment" link="#asset_equipment" />
			<AssetsList type="equipment" />
		</section>
	);
}

export default RoomsView;
