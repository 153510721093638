import React, { useContext, useState } from 'react';

import ModalsContext from '#context/modals.jsx';
import bookingsContext from '#context/bookings.jsx';

const EditCustom = () => {
	const modals_context = useContext(ModalsContext);
	const bookings_context = useContext(bookingsContext);

	const [data, set_data] = useState(modals_context.data);

	const on_change = (uuid, value) => {
		data[uuid].value = value;
		set_data({ ...data });
	};

	const custom_fields = Array.from(Object.values(data))
		.map((obj) => obj.source)
		.sort((a, b) => a.index - b.index);

	const on_submit = async (e) => {
		e.preventDefault();
		e.stopPropagation();

		await bookings_context.api.update_custom(data);
		modals_context.hide();

		return false;
	};

	return (
		<div className="popup scrollable">
			<form className="account_form booking" onSubmit={on_submit}>
				<h3>Custom Form Inputs</h3>

				{custom_fields.map(({ uuid, title, type, required, placeholder, options, display }) => {
					switch (type) {
						case 'Radio':
							const radio_options = options.split('\n');
							return (
								<div className="input" key={uuid}>
									<label>
										{display ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
									{radio_options.map((option) => (
										<div className="form_radio" key={`${uuid}_${option}`}>
											<input
												type="radio"
												name={uuid}
												value={option}
												checked={data[uuid].value === option}
												id={`${uuid}_${option}`}
												required={required}
												onChange={(e) => on_change(uuid, e.target.value)}
											/>
											<label htmlFor={`${uuid}_${option}`}>{option}</label>
										</div>
									))}
									<span className="error"></span>
								</div>
							);

						case 'Checkboxes':
							const checkbox_options = options.split('\n');
							return (
								<div className="input" key={uuid}>
									<label>
										{display ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
									{checkbox_options.map((option, i) => (
										<div className="input checkbox" key={`${uuid}_${option}`}>
											<input
												type="checkbox"
												name={uuid}
												value={option}
												checked={data[uuid].value[i]}
												id={`${uuid}_${option}`}
												onChange={(e) => {
													data[uuid].value[i] = e.target.checked;
													on_change(uuid, data[uuid].value);
												}}
											/>
											<label htmlFor={`${uuid}_${option}`}>{option}</label>
										</div>
									))}
									<span className="error"></span>
								</div>
							);

						case 'Text Field Input':
							return (
								<div className="input" key={uuid}>
									<label htmlFor={uuid}>
										{display || required ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
									<input
										type="text"
										id={uuid}
										placeholder={placeholder}
										required={required}
										value={data[uuid].value}
										onChange={(e) => on_change(uuid, e.target.value, required)}
									/>
									<span className="error"></span>
								</div>
							);

						case 'Long Text Field Input':
							return (
								<div className="input" key={uuid}>
									<label htmlFor={uuid}>
										{display ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
									<textarea
										id={uuid}
										placeholder={placeholder}
										required={required}
										value={data[uuid].value}
										onChange={(e) => on_change(uuid, e.target.value, required)}
									></textarea>
									<span className="error"></span>
								</div>
							);

						case 'Number Input':
							return (
								<div className="input" key={uuid}>
									<label htmlFor={uuid}>
										{display ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
									<input
										type="number"
										id={uuid}
										placeholder={placeholder}
										required={required}
										value={data[uuid].value}
										onChange={(e) => on_change(uuid, +e.target.value, required)}
									/>
									<span className="error"></span>
								</div>
							);

						case 'Checkbox':
							return (
								<div className="input checkbox" key={uuid}>
									<input
										type="checkbox"
										id={uuid}
										required={required}
										checked={data[uuid].value}
										onChange={(e) => on_change(uuid, e.target.checked, required)}
									/>
									<label htmlFor={uuid}>
										{display ? title : ''}
										{required ? <span>*</span> : ''}
									</label>
								</div>
							);
						case 'Note':
							return (
								<div className="note" key={uuid}>
									<div className="text" dangerouslySetInnerHTML={{ __html: title }}></div>
								</div>
							);
						case 'Divider':
							return <hr key={uuid} />;

						default:
							return <></>;
					}
				})}

				<div className="actions">
					<button
						onClick={(e) => {
							e.preventDefault();
							modals_context.hide();
						}}
					>
						Cancel
					</button>

					<button type="submit">Save</button>
				</div>
			</form>
		</div>
	);
};

export default EditCustom;
