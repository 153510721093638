import React from 'react';

export const get_rooms = (rooms) => {
	if (!rooms) return <></>;
	if (!Object.entries(rooms).length) return <></>;

	let res = [];

	Object.values(rooms).forEach((arr) => arr.forEach((room) => res.push(`${room.roomType} ${room.name}`)));

	return res.join('\n');
};

export const get_pretty_action = (action, user) => {
	let action_str = '';

	if (!user.first_name && !user.last_name) {
		action_str = 'Guest ';
	} else {
		action_str = `${user.first_name} ${user.last_name} `;
	}

	switch (action) {
		case 'created':
			action_str += 'created the booking request';
			break;
		case 'update_status':
			action_str += 'updated status';
			break;
		case 'update_time':
			action_str += 'updated time';
			break;
		case 'update_request':
			action_str += 'updated request information';
			break;
		case 'update_schedule':
			action_str += 'updated booking information';
			break;
		case 'update_custom':
			action_str += 'updated custom fields';
			break;
		case 'add_note':
			action_str += 'added comment';
			break;
		case 'upload_file':
			action_str += 'uploaded file';
			break;
		case 'delete_file':
			action_str += 'deleted file';
			break;
		default:
			action_str = action;
			break;
	}

	return action_str;
};
