import React, { useContext, useEffect, useState } from 'react';

import UserContext from '#context/user.jsx';
import BookingsContext from '#context/bookings.jsx';
import NotificationsContenxt from '#context/notifications.jsx';
import AssetsContext from '#context/assets.jsx';
import { full_date, pretty_period } from '#components/time_helpers.js';
import { prettier } from '#components/time_helpers.js';
import Loader from '#components/loader.jsx';
import NotificationEntry from '#components/notification_entry.jsx';
import { get_rooms } from '#utils/index.jsx';
import ModalsContext from '#context/modals.jsx';

import { DateTime } from 'luxon';

import WYSIWYGTextArea from '#components/wysiwyg_textarea.jsx';

const Header = ({ booking }) => (
	<div className="header_block">
		<a className="icon border close" href="#"></a>
		<h2>{booking.status === 'blocked' ? 'Blocked Time' : 'Booking Request'}</h2>
		<div className="actions">
			{/* <a className="icon border edit"></a> */}
			<a className="icon border print" onClick={window.print}></a>
		</div>
	</div>
);

const _status2text = new Map([
	['created', (name) => `Submitted by ${name}`],
	['approved', (name) => `Approved by ${name}`],
	['cancelled', (name) => `Canceled by ${name}`],
	['declined', (name) => `Declined by ${name}`],
	['pending', (name) => `Left pending by ${name}`],
	['update_request', (name) => `Request information updated by ${name}`],
	['update_schedule', (name) => `Booking information updated by ${name}`],
	['update_custom', (name) => `Custom information updated by ${name}`],
	['auto_approved', () => `Auto approved by the system`],
]);

const Statuses = ({ booking }) => {
	if (!booking.statuses) return <></>;

	return (
		<ul className="statuses">
			{booking.statuses
				.sort((a, b) => a.timestamp - b.timestamp)
				.map((status) => (
					<li key={status.timestamp}>
						{_status2text.get(status.action)(status.user.first_name ? `${status.user.first_name} ${status.user.last_name}` : 'guest')} at{' '}
						{full_date(status.timestamp)}
					</li>
				))}
		</ul>
	);
};

const RequestInformation = ({ booking }) => {
	const modals_context = useContext(ModalsContext);

	return (
		<div className="block">
			<h3>
				Requestor Information{' '}
				<a
					className="icon edit"
					onClick={() =>
						modals_context.show('edit_request', {
							...booking.requestInformation,
							attendees: booking.requestInformation.Attendees,
							details: booking.additionalDetails,
						})
					}
				></a>
			</h3>
			<dl>
				<dt>Name</dt>
				<dd>{booking.requestInformation.name}</dd>

				<dt>Email</dt>
				<dd>{booking.requestInformation.email}</dd>

				<dt>Phone Number</dt>
				<dd>{booking.requestInformation.phone}</dd>

				<dt>Session Name</dt>
				<dd>{booking.requestInformation.session_name}</dd>

				<dt>Number of Attendees</dt>
				<dd>{booking.requestInformation.Attendees}</dd>

				<dt>Details</dt>
				<dd>{booking.additionalDetails}</dd>
			</dl>
		</div>
	);
};

const BookingInformation = ({ booking }) => {
	const modals_context = useContext(ModalsContext);
	const { user } = useContext(UserContext);

	const { schedule } = booking;

	return (
		<div className="block">
			<h3>
				Booking Information
				{user?.admin ? (
					<a
						className="icon edit"
						onClick={() => {
							const rooms = {};
							Object.values(schedule.rooms).forEach((arr) => arr.forEach((room) => (rooms[room._id || room.uuid] = true)));

							modals_context.show('edit_schedule', {
								date: DateTime.fromJSDate(new Date(schedule.date)).toFormat('yyyy-MM-dd'),
								start: schedule.times[0].start,
								setup: schedule.times[0].end - schedule.times[0].start,
								duration: schedule.times[1].end - schedule.times[1].start,
								cleanup: schedule.times[2].end - schedule.times[2].start,
								rooms,
								blocked: booking.status === 'blocked',
							});
						}}
					></a>
				) : (
					<></>
				)}
			</h3>
			<dl>
				<dt>Date</dt>
				<dd>{schedule.date}</dd>

				{booking.status === 'blocked' ? (
					<React.Fragment key={0}>
						<dt>{schedule.times[0].title}</dt>
						<dd>{`${prettier(schedule.times[0].start)} - ${prettier(schedule.times[0].end)}`}</dd>
					</React.Fragment>
				) : (
					schedule.times?.map(({ start, end, title }, i) => (
						<React.Fragment key={i}>
							<dt>{title}</dt>
							<dd>{`${prettier(start)} - ${prettier(end)}`}</dd>
						</React.Fragment>
					))
				)}

				<dt>Total Duration</dt>
				<dd>{pretty_period(schedule.times[schedule.times.length - 1].end - schedule.times[0].start)}</dd>

				<dt>Assigned Room(s)</dt>
				<dd>{get_rooms(schedule.rooms)}</dd>
			</dl>
		</div>
	);
};

const AssetsInformation = ({ booking }) => {
	const modals_context = useContext(ModalsContext);
	const { user } = useContext(UserContext);
	const assets_context = useContext(AssetsContext);
	const [current_assets, set_current_assets] = useState();

	if (!booking.assets) booking.assets = [];

	useEffect(() => {
		if (booking.assets.length === 0) {
			set_current_assets([]);
		} else {
			(async () => {
				set_current_assets(await assets_context.get_list_for_booking(booking._id));
			})();
		}
	}, [booking.assets]);

	let people = [];
	let equipment = [];

	if (current_assets) {
		current_assets.forEach((asset) => {
			switch (asset.type) {
				case 'staff':
					if (booking.assets.indexOf(asset._id) !== -1) people.push(asset.name);
					break;
				case 'equipment':
					asset.ids.forEach((id) => {
						if (booking.assets.indexOf(asset._id + '_' + id._id) !== -1) equipment.push(`${asset.name} - ${id.name}`);
					});
					break;
			}
		});

		people = people.map((name, i) => (
			<>
				<dt>{i === 0 ? 'People' : ''}</dt>
				<dd>{name}</dd>
			</>
		));
		equipment = equipment.map((name, i) => (
			<>
				<dt>{i === 0 ? 'Equipment' : ''}</dt>
				<dd>{name}</dd>
			</>
		));
	}

	return (
		<div className="block">
			<h3>
				Assets
				{user?.admin || user?.staff ? (
					<a
						className="icon edit"
						onClick={() => {
							modals_context.show('edit_assets', booking);
						}}
					></a>
				) : (
					<></>
				)}
			</h3>
			{!current_assets ? (
				<Loader />
			) : (
				<dl>
					{people.length || equipment.length ? (
						<>
							{people}
							{equipment}
						</>
					) : (
						<dd>No assets attached</dd>
					)}
				</dl>
			)}
		</div>
	);
};

const CustomInputType = ({ entry }) => {
	switch (entry.source.type) {
		case 'Text Field Input':
		case 'Long Text Field Input':
		case 'Number Input':
		case 'Radio':
			return (
				<>
					<dt>{entry.source.title}</dt>
					<dd>{entry.value || 'N/A'}</dd>
				</>
			);
		case 'Checkbox':
			return (
				<>
					<dt>{entry.source.title}</dt>
					<dd className="checkbox">{entry.value ? '🗹' : '☐'}</dd>
				</>
			);
		case 'Checkboxes':
			return (
				<>
					<dt>{entry.source.title}</dt>
					<dd></dd>
					{entry.source.options.split('\n').map((option, i) => (
						<>
							<dt key={`dt_${option}_${i}`} className="checkbox">
								{option}
							</dt>
							<dd key={`dd_${option}_${i}`} className="checkbox">
								{entry.value && entry.value[i] ? '🗹' : '☐'}
							</dd>
						</>
					))}
				</>
			);
		default:
			return <></>;
	}
};

const CustomInputSection = ({ section, i }) => {
	const content = [];
	let table;

	for (let i = 0; i < section.length; i++) {
		const entry = section[i];
		if (entry.source.type === 'Note') {
			if (table) {
				content.push(table);
				table = undefined;
			}
			content.push(<h4 key={`${i}_h4`} dangerouslySetInnerHTML={{ __html: entry.source.title }}></h4>);
		} else {
			if (!table) table = [];
			table.push(<CustomInputType key={`${i}_cit`} entry={entry} />);
		}
	}

	if (table) content.push(table);

	return content;
};

const CustomInformation = ({ booking }) => {
	const modals_context = useContext(ModalsContext);

	if (!booking.customValues) return <></>;
	if (!Object.entries(booking.customValues).length) return <></>;

	const sections = [];
	Object.entries(booking.customValues)
		.map(([key, value]) => ({ key, ...value }))
		.sort((a, b) => a.source.index - b.source.index)
		.forEach((entry) => {
			if (entry.source.type === 'Divider') {
				sections.push([]);
			} else {
				if (!sections.length) sections.push([]);
				sections[sections.length - 1]?.push(entry);
			}
		});

	return (
		<div className="block custom">
			<h3 key={'header'}>
				Custom Form Inputs{' '}
				<a className="icon edit" onClick={() => modals_context.show('edit_custom', JSON.parse(JSON.stringify(booking.customValues)))}></a>
			</h3>

			<>
				{sections.map((section, i) => (
					<>
						<CustomInputSection section={section} key={i} i={i} />
						{i !== sections.length - 1 ? <hr key={`${i}_hr`} /> : <></>}
					</>
				))}
			</>
		</div>
	);
};

function FileView({ file, user, delete_file }) {
	const [confirmation, set_confirmation] = useState(false);

	let text = 'uploaded';

	if (file.user && Object.entries(file.user).length) {
		text += ` by ${file.user.first_name} ${file.user.last_name}`;
	} else {
		text += ' by Guest';
	}

	if (file.timestamp) text += ` on ${full_date(file.timestamp)}`;

	return (
		<li>
			<div className="info">
				<a target="_blank" href={file.link} rel="noreferrer">
					<p>{file.name}</p>
				</a>
				<p className="subinfo">{text}</p>
			</div>

			<div className="actions">
				{confirmation ? (
					<div className="confirmation">
						<span>Are you sure?</span>
						<div className="icons">
							<a className="icon approve" onClick={() => delete_file(file._id)}></a>
							<a className="icon close_red" onClick={() => set_confirmation(false)}></a>
						</div>
					</div>
				) : (
					<a className="icon close_small" onClick={() => set_confirmation(true)}></a>
				)}
			</div>
		</li>
	);
}

function FilesSection() {
	const { booking, api } = useContext(BookingsContext);
	const [error, set_error] = useState('');
	const [is_sending, set_is_sending] = useState(false);

	const upload = async (e) => {
		set_is_sending(true);
		set_error('');
		let file = e.target.files[0];

		if (file.size > 100000000) {
			set_is_sending(false);
			set_error('Attachments must be no larger than 100mb');
			return;
		}

		file = new File([file], file.name.replace(/\s/gi, '_'));
		try {
			api.add_file(file);
		} catch (e) {
			set_error('Server-side error, please try again later');
		}

		set_is_sending(false);
	};

	const delete_file = async (file) => {
		try {
			await api.delete_file(file);
		} catch (e) {
			console.log(e);
			return;
		}
	};

	return (
		<div className="block files">
			<h3>File Upload</h3>
			<p className="description">Please upload any files (PowerPoint, Media, etc.) required for session.</p>

			<label className="button">
				<input type="file" onChange={upload} accept="*" disabled={is_sending ? 'disabled' : ''} />
				Select File
			</label>
			<span className="upload_info">Max 100mb</span>
			{is_sending && <span className={`sending ${error ? 'error' : ''}`}>{error ? error : 'Uploading...'}</span>}

			{booking?.files.length ? <h4>Uploaded Files</h4> : <></>}
			<ul>
				{(booking?.files || []).map((file) => (
					<FileView file={file} delete_file={delete_file} />
				))}
			</ul>
		</div>
	);
}

const Note = ({ note }) => {
	return (
		<li>
			<h5>{note.user && Object.entries(note.user).length ? `${note.user.first_name} ${note.user.last_name}` : 'Guest'}</h5>
			<div dangerouslySetInnerHTML={{ __html: note.note }}></div>
			<p className="subinfo">{full_date(note.timestamp)}</p>
		</li>
	);
};

function Notes() {
	const [note, set_note] = useState('');
	const [error, set_error] = useState('');
	const [is_sending, set_is_sending] = useState(false);
	const { booking, api } = useContext(BookingsContext);

	const send = async () => {
		set_is_sending(true);
		try {
			await api.add_note(note);
			set_note('');
		} catch (e) {
			// e = 'api_error'
			set_error('Server-side error, please try again later');
		}

		set_is_sending(false);
	};

	if (!booking.notes) booking.notes = [];
	return (
		<div className="block comments">
			<h3>Comments</h3>

			<ul>
				{booking.notes.map((note, i) => (
					<Note note={note} key={i} />
				))}
			</ul>

			<form
				onSubmit={(e) => {
					e.preventDefault();
					send();
				}}
			>
				<WYSIWYGTextArea placeholder="Add a comment" value={note} onChange={(e) => set_note(e.target.value)} required />
				<button className="button" type="submit" disabled={is_sending && !note ? 'disabled' : ''}>
					Add Comment
				</button>
				{is_sending && note && <span className={`sending ${error ? 'error' : ''}`}>{error ? error : 'Sending...'}</span>}
			</form>
		</div>
	);
}

function Notifications({ booking }) {
	const notifications_context = useContext(NotificationsContenxt);
	const bookings_context = useContext(BookingsContext);

	if (!booking.notifications || !booking.notifications.length) return <></>;

	return (
		<div className="block notifications">
			<h3>
				Notifications{' '}
				<a
					href="#"
					onClick={async (e) => {
						e.stopPropagation();
						e.preventDefault();
						try {
							await notifications_context.api.seen_all(booking._id);
							bookings_context.api.notifications_seen();
						} catch (e) {
							console.error(e);
						}
					}}
				>
					Mark all as seen
				</a>
			</h3>

			<ul>
				{booking.notifications.map((notification) => (
					<NotificationEntry notification={notification} key={notification._id} />
				))}
			</ul>
		</div>
	);
}

const Actions = () => {
	const { user } = useContext(UserContext);
	const { booking, api } = useContext(BookingsContext);
	const modals_context = useContext(ModalsContext);

	if (booking.status === 'completed') return <></>;

	const clb = api.update_status;

	return (
		<div className="buttons">
			{(booking.status === 'pending') & (user?.admin || user?.staff) ? (
				<>
					<a className="button warning" onClick={() => modals_context.show('booking_status', { status: 'decline', clb })}>
						Decline Request
					</a>
					<a className="button" onClick={() => modals_context.show('booking_status', { status: 'pending', clb })}>
						Remain Pending
					</a>
					<a className="button approve" onClick={() => modals_context.show('booking_status', { status: 'approve', clb })}>
						Approve Request
					</a>
				</>
			) : (
				<a className="button warning" onClick={() => modals_context.show('booking_status', { status: 'cancel', clb })}>
					Cancel Booking
				</a>
			)}
		</div>
	);
};

const BookingRequest = ({ data }) => {
	const { user } = useContext(UserContext);
	const { booking, get_booking } = useContext(BookingsContext);

	useEffect(() => {
		if (!data[0]) return;
		get_booking(data[0]);
	}, [data[0]]);

	if (!booking) return <Loader />;

	return (
		<div className="request_item">
			<Header booking={booking} />
			<Statuses booking={booking} />
			<RequestInformation booking={booking} />
			<BookingInformation booking={booking} />
			<AssetsInformation booking={booking} />
			<CustomInformation booking={booking} />
			<FilesSection />
			<Notes />
			<Notifications booking={booking} />
			<Actions />
		</div>
	);
};

export default BookingRequest;
