import React, { useState, useContext } from 'react';

import NotificationsContext from '#context/notifications.jsx';

import api_bookings from '#api/bookings.js';

import UserContext from '#context/user.jsx';

const BookingsContext = React.createContext();

const _fields_to_update = ['result', 'schedule', 'requestInformation', 'status', 'blockedTime', 'remained_pending'];

let calendar_date;

function BookingsProvider(props) {
	const { session } = useContext(UserContext);
	const notifications_context = useContext(NotificationsContext);
	const [list, list_set] = useState(undefined);
	const [calendar_list, calendar_list_set] = useState(undefined);
	const [booking, booking_set] = useState(undefined);

	const get_booking = async (booking) => {
		booking_set(undefined);
		booking_set(await api_bookings.get({ session, booking }));
	};

	const get_list = async (data) => {
		list_set(undefined);
		list_set(await api_bookings.requests({ session, ...data }));
	};

	const get_calendar = async (date) => {
		calendar_date = date;
		calendar_list_set(undefined);
		calendar_list_set(await api_bookings.admin_calendar({ session, date }));
	};

	const get_asset_calendar = async ({ year, month, assets }) => {
		return await api_bookings.asset_calendar({
			session,
			year,
			month,
			assets,
		});
	};

	const notifications_seen = () => {
		let f = false;
		booking.notifications.forEach((notification) => {
			if (notification.seen) return;
			notification.seen = true;
			f = true;
		});

		if (!f) return;

		notifications_context.update_counters();

		booking_set({ ...booking });

		if (list) {
			const list_entry = list.find(({ _id }) => _id === booking._id);
			if (list_entry) {
				list_entry.notifications = 0;
				list_set([...list]);
			}
		}
	};

	const notifications_seen_and_booking_updated = (booking) => {
		let f = false;
		booking.notifications.forEach((notification) => {
			if (notification.seen) return;
			notification.seen = true;
			f = true;
		});

		if (f) notifications_context.update_counters();

		booking_set({ ...booking });

		if (list) {
			const list_entry = list.find(({ _id }) => _id === booking._id);
			if (list_entry) {
				list_entry.notifications = 0;
				_fields_to_update.forEach((field) => (list_entry[field] = booking[field]));
				list_set([...list]);
			}
		}

		if (calendar_list) {
			const calendar_list_entry = calendar_list.find(({ _id }) => _id === booking._id);
			if (calendar_list_entry) {
				calendar_list_entry.notifications = 0;
				_fields_to_update.forEach((field) => (calendar_list_entry[field] = booking[field]));
				calendar_list_set([...calendar_list]);
			}
		}
	};

	const store = {
		get booking() {
			return booking;
		},
		set booking(_) {},
		get list() {
			return list;
		},
		set list(_) {},
		get calendar_list() {
			return calendar_list;
		},
		set calendar_list(_) {},
		get_booking,
		get_list,
		get_calendar,
		get_asset_calendar,
		api: {
			analytics: async ({ from, to }) => {
				return await api_bookings.analytics({ session, from, to });
			},
			add_file: async (file) => {
				const new_file = await api_bookings.add_file({
					booking: session ? booking._id : booking.token,
					session,
					file,
				});

				if (!booking.files) booking.files = [];
				booking.files.push(new_file);

				notifications_seen();

				booking_set({ ...booking });
			},
			delete_file: async (file) => {
				await api_bookings.delete_file({
					booking: session ? booking._id : booking.token,
					session,
					file,
				});

				booking.files = booking.files.filter((entry) => entry._id !== file);

				notifications_seen();

				booking_set({ ...booking });
			},
			add_note: async (note) => {
				const new_note = await api_bookings.add_note({
					booking: session ? booking._id : booking.token,
					session,
					note,
				});

				if (!booking.notes) booking.notes = [];
				booking.notes.push(new_note);

				notifications_seen();

				booking_set({ ...booking });
			},
			update_request: async ({ name, email, phone, session_name, attendees, details }) => {
				const response = await api_bookings.update_request({
					booking: session ? booking._id : booking.token,
					session,
					name,
					email,
					phone,
					session_name,
					attendees,
					details,
				});

				notifications_seen_and_booking_updated(response);

				booking_set({ ...response });
			},
			update_schedule: async ({ date, start, setup, duration, cleanup, rooms }) => {
				const response = await api_bookings.update_schedule({
					booking: booking._id,
					session,
					date,
					start,
					setup,
					duration,
					cleanup,
					rooms,
				});

				notifications_seen_and_booking_updated(response);

				booking_set({ ...response });
			},
			update_assets: async (assets) => {
				const response = await api_bookings.update_assets({
					booking: booking._id,
					session,
					assets,
				});

				notifications_seen_and_booking_updated(response);

				booking_set({ ...response });
			},
			update_custom: async (info) => {
				const response = await api_bookings.update_custom({
					booking: session ? booking._id : booking.token,
					session,
					info,
				});

				notifications_seen_and_booking_updated(response);

				booking_set({ ...response });
			},
			notification_seen: (_id, seen) => {
				if (!booking) return;

				let f = false;
				booking.notifications.forEach((notification) => {
					if (notification._id !== _id) return;

					notification.seen = seen;
					f = true;
				});

				if (!f) return;

				notifications_context.update_counters();

				booking_set({ ...booking });

				if (list) {
					const list_entry = list.find(({ _id }) => _id === booking._id);
					if (list_entry) {
						list_entry.notifications += seen ? -1 : 1;
						list_set([...list]);
					}
				}
			},
			notifications_seen,
			update_status: async (data) => {
				const response = await api_bookings.update_status({
					booking: session ? booking._id : booking.token,
					session,
					...data,
				});

				notifications_seen_and_booking_updated(response);

				booking_set(response);
			},
			block_time: async (data) => {
				await api_bookings.block_time({
					session,
					...data,
				});

				if (calendar_date) get_calendar(calendar_date);
			},
		},
	};

	return <BookingsContext.Provider value={store}>{props.children}</BookingsContext.Provider>;
}

export default BookingsContext;
export { BookingsProvider };
