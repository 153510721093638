import React, { useState, useEffect, useContext } from 'react';

import { matchRoutes, useLocation, NavLink } from 'react-router-dom';

import NotificationsContenxt from '#context/notifications.jsx';
import UserContext from '#context/user.jsx';
import OrganizationContext from '#context/organization.jsx';

const routes = [
	{ path: '/dashboard/', cls: 'requests' },
	{ path: '/dashboard/requests', cls: 'requests' },
	{ path: '/dashboard/requests/:status', cls: 'requests' },
	{ path: '/dashboard/requests/:status/:year/:month', cls: 'requests' },
	{ path: '/dashboard/notifications', cls: 'notifications' },
	{ path: '/dashboard/notifications/:unseen', cls: 'notifications' },
	{ path: '/dashboard/facility', cls: 'details' },
	{ path: '/dashboard/request_form', cls: 'request_form' },
	{ path: '/dashboard/rooms', cls: 'rooms' },
	{ path: '/dashboard/assets', cls: 'assets' },
	{ path: '/dashboard/analytics', cls: 'analytics' },
	{ path: '/dashboard/analytics/:from/:to', cls: 'analytics' },
	{ path: '/dashboard/calendar', cls: 'calendar' },
	{ path: '/dashboard/calendar/:date', cls: 'calendar' },
	{ path: '/dashboard/feedback_form', cls: 'feedback' },
	{ path: '/dashboard/users', cls: 'accounts' },
	{ path: '/dashboard/users/:role', cls: 'accounts' },
];

function is_current(cls, location) {
	const data = matchRoutes(routes, location);

	if (!data) return;
	if (!data[0]) return;
	if (!data[0].route) return;

	if (data[0].route.cls !== cls) return;

	return true;
}

function Counter({ count }) {
	if (!count) return <></>;

	return <div className="counter">{count}</div>;
}

function MenuItem({ text, count, url, cls, clb }) {
	const location = useLocation();

	cls = `menu_item ${cls} ${is_current(cls, location) ? 'selected' : ''}`;

	const content = (
		<>
			<span className="icon" />
			{text}
			<Counter count={count} />
		</>
	);

	if (clb)
		return (
			<a href="#!" onClick={clb} className={cls}>
				{content}
			</a>
		);

	return (
		<NavLink to={url} className={cls}>
			{content}
		</NavLink>
	);
}

function Sidebar() {
	const [expanded, expanded_set] = useState(false);

	const user_context = useContext(UserContext);
	const { organization } = useContext(OrganizationContext);
	const notifications_context = useContext(NotificationsContenxt);

	useEffect(() => {
		notifications_context.update_counters();
	}, [1]);

	const user = user_context.user;

	let menus = [];

	if (user.admin) {
		menus = [
			{
				text: 'Facility Details',
				url: '/dashboard/facility',
				cls: 'details',
			},
			{
				text: 'Room Types & Quantities',
				url: '/dashboard/rooms',
				cls: 'rooms',
			},
			{
				text: 'Asset Inventory',
				url: '/dashboard/assets',
				cls: 'assets',
			},
			{
				text: 'Booking Request Form',
				url: '/dashboard/request_form',
				cls: 'request_form',
			},
			{
				text: 'Feedback Form',
				url: '/dashboard/feedback_form',
				cls: 'feedback',
			},
			{
				text: 'Calendar',
				url: '/dashboard/calendar',
				cls: 'calendar',
			},
			{
				text: 'Analytics',
				url: '/dashboard/analytics',
				cls: 'analytics',
			},
			{
				text: 'Accounts',
				url: '/dashboard/users',
				cls: 'accounts',
			},
		];
	} else if (user.staff) {
		menus = [
			{
				text: 'Asset Inventory',
				url: '/dashboard/assets',
				cls: 'assets',
			},
			{
				text: 'Feedback Form',
				url: '/dashboard/feedback_form',
				cls: 'feedback',
			},
			{
				text: 'Calendar',
				url: '/dashboard/calendar',
				cls: 'calendar',
			},
			{
				text: 'Analytics',
				url: '/dashboard/analytics',
				cls: 'analytics',
			},
		];
	}

	return (
		<div className="sidebar">
			<div>
				<div className="user">
					{user.photo ? (
						<img src={user.photo} alt="user" className="photo" />
					) : (
						<span className={user ? 'registered' : ''} data-name={user ? `${user.first_name[0]}${user.last_name[0]}` : ''} />
					)}
					<div className="info">
						<div className="name">
							{user.first_name} {user.last_name}
						</div>
						<div className="email">{user.email}</div>
					</div>
					<div className={`expand_menu ${expanded ? 'selected' : ''}`} onClick={() => expanded_set(!expanded)} />
				</div>

				<div className={`menu expandable ${expanded ? 'expanded' : ''}`}>
					<MenuItem text="Edit Profile" url="#profile" cls="profile_settings" />
					<MenuItem text="Logout" clb={() => user_context.log_out()} cls="logout" />
				</div>
				<div className="menu">
					<div className="title">BOOKINGS</div>
					<MenuItem text="Requests" url="/dashboard/requests" count={0 /*notifications_context.unseen_bookings*/} cls="requests" />
					<MenuItem text="Notifications" url="/dashboard/notifications" count={notifications_context.unseen_notifications} cls="notifications" />
				</div>
				{menus.length ? (
					<div className="menu">
						<div className="title">ADMINISTRATION</div>
						{menus.map((data, i) => (
							<MenuItem key={i} {...data} />
						))}
					</div>
				) : (
					<></>
				)}
				<NavLink className="border_button" to={'/new_booking/rooms'}>
					Book a Room
				</NavLink>
			</div>
			<img src={organization.logo} alt="logo" className="logo" />
		</div>
	);
}

export default Sidebar;
