import React, { useState, useRef, useEffect } from 'react';

import { DateTime } from 'luxon';
import Loader from '#components/loader.jsx';

import { format_date_YYYY_MM_DD } from '#components/time_helpers.js';

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

function DateElement({ year, month, day, cls = '', clb }) {
	return (
		<li className={cls} onClick={() => clb(format_date_YYYY_MM_DD({ year, month, day }))}>
			{day}
		</li>
	);
}

export function generateCalendar({ year, month, dates_cls = {}, clb }) {
	let date_current = DateTime.local(year, month, 1);
	let date_prev = DateTime.local(year, month, 1).minus({ months: 1 });
	let date_next = DateTime.local(year, month, 1).plus({ months: 1 });

	let first_day = date_current.weekday - 1;
	if (first_day < 0) first_day += 7;

	let dates = [];

	let day_of_week = 0;

	if (first_day < 6)
		for (let i = 0; i <= first_day; i++) {
			let day = date_prev.daysInMonth - first_day + i;
			dates.push(
				<DateElement key={`${date_prev.year}-${date_prev.month}-${day}`} year={date_prev.year} month={date_prev.month} day={day} cls="inactive" />
			);
			day_of_week++;
		}

	for (let i = 1; i <= date_current.daysInMonth; i++) {
		dates.push(
			<DateElement
				key={`${date_current.year}-${date_current.month}-${i}`}
				year={date_current.year}
				month={date_current.month}
				day={i}
				cls={dates_cls[i]}
				clb={clb}
			/>
		);
		day_of_week++;
	}

	day_of_week = day_of_week % 7;

	if (day_of_week >= 1)
		for (let i = 1; i + day_of_week <= 7; i++) {
			dates.push(
				<DateElement key={`${date_next.year}-${date_next.month}-${i}`} year={date_next.year} month={date_next.month} day={i} cls="inactive" />
			);
		}

	return dates;
}

function Dropdown({ cls = '', selection, selected, clb, disabled, loading, on_month_change, minimum, maximum, dates_cls, opened = false }) {
	const [start_opened, set_opened] = useState(opened);
	const [open, set_open] = useState(false);

	const [year, set_year] = useState(selected ? +selected.split('-')[0] : DateTime.now().year);
	const [month, set_month] = useState(selected ? +selected.split('-')[1] : DateTime.now().month);

	const ref = useRef();

	useEffect(() => {
		if (start_opened) {
			click_open();
		}
		set_opened(false);
	}, [opened]);

	const click_outside = (e) => {
		if (ref.current && !ref.current.contains(e.target)) set_open(false);
	};

	const click_open = (e) => {
		set_open(!open);
		if (!open && on_month_change) on_month_change({ year, month });
	};

	let click_prev = (e) => {
		let date = DateTime.local(year, month, 1).minus({ months: 1 });
		set_year(date.year);
		set_month(date.month);
		if (on_month_change) on_month_change(date);
	};

	if (minimum) {
		if (year < minimum.year) {
			click_prev = undefined;
		} else if (year === minimum.year) {
			if (month < minimum.month) {
				click_prev = undefined;
			} else if (month === minimum.month) {
				click_prev = undefined;
			}
		}
	}

	let click_next = (e) => {
		let date = DateTime.local(year, month, 1).plus({ months: 1 });
		set_year(date.year);
		set_month(date.month);
		if (on_month_change) on_month_change(date);
	};

	if (maximum) {
		if (year > maximum.year) {
			click_next = false;
		} else if (year === maximum.year) {
			if (month > maximum.month) {
				click_next = false;
			} else if (month === maximum.month) {
				click_next = false;
			}
		}
	}

	useEffect(() => {
		document.addEventListener('click', click_outside);
		return () => {
			document.removeEventListener('click', click_outside);
		};
	}, []);

	return (
		<div
			className={`dropdown calendar${cls ? ` ${cls}` : ''}${open ? ' open' : ''}${selected ? ' selected' : ''}${disabled ? ' disabled' : ''}`}
			ref={ref}
		>
			<div className="selection" onClick={click_open}>
				{selection}
			</div>
			<div className="calendar_content">
				<div className="header">
					<div className={`arrow left${!click_prev ? ' disabled' : ''}`} onClick={click_prev}></div>
					<div className="current_date">{`${months[month - 1]} ${year}`}</div>
					<div className={`arrow right${!click_next ? ' disabled' : ''}`} onClick={click_next}></div>
				</div>
				<div className="body">
					<ul className="weekdays">
						<li>S</li>
						<li>M</li>
						<li>T</li>
						<li>W</li>
						<li>T</li>
						<li>F</li>
						<li>S</li>
					</ul>
					<ul className="days">
						{generateCalendar({
							year,
							month,
							dates_cls,
							clb: (value) => {
								set_open(false);
								clb(value);
							},
						})}
					</ul>
				</div>
				{loading && (
					<div className="overlay">
						<Loader />
					</div>
				)}
			</div>
		</div>
	);
}

export default Dropdown;
