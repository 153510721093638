import React, { useState, useEffect, useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import UserContext from '#context/user.jsx';
import OrganizationContext from '#context/organization.jsx';
import ModalsContext from '#context/modals.jsx';
import IsMobileContext from '#context/is_mobile.jsx';

import '#scss/headerbar.scss';

const Left = ({ page, back_clb, is_first_page, disabled }) => {
	const organization_context = useContext(OrganizationContext);
	const is_mobile = useContext(IsMobileContext);
	let content;

	if (is_mobile) {
		if (page === '/sign_in' || page === '/create_account') {
			content = <img src="/img/logo.png" className="logo" alt="Logo" />;
		} else if (!is_first_page) {
			content = <img src="/img/arrow_left.svg" onClick={!disabled && back_clb} alt="Go back" />;
		}
	} else {
		if (organization_context.organization) {
			content = <img src={organization_context.organization.logo} className="logo" alt=" " />;
		}
	}

	return <div className="left">{content}</div>;
};

const Right = ({ page }) => {
	const { user } = useContext(UserContext);
	const modals_context = useContext(ModalsContext);
	const is_mobile = useContext(IsMobileContext);

	const first_name = user?.first_name || '';
	const last_name = user?.last_name || '';
	const initials = user ? `${first_name[0]}${last_name[0]}` : '';

	const navigate = useNavigate();

	const contact_us_clb = () => modals_context.show('contact_us');
	const sign_in_clb = () => modals_context.show('authentication', { clb: () => {} });
	const calendar_clb = () => navigate('/calendar');
	const book_clb = () => navigate('/new_booking/rooms');
	let content;

	switch (page) {
		case '/create_account':
		case '/sign_in':
			content = (
				<>
					<a href="/calendar" className="calendar" title="Calendar"></a>
					<button
						title="Contact Us"
						className={`contact_us ${modals_context.modal === 'contact_us' && modals_context.opened ? 'active' : ''}`}
						onClick={contact_us_clb}
					>
						?
					</button>
					<NavLink title="Book a Room" to="/new_booking/rooms" className="btn_book">
						Book a Room
					</NavLink>
				</>
			);

			break;
		case '/calendar':
			if (is_mobile) {
				content = <BurgerMenu book_clb={book_clb} contact_us_clb={contact_us_clb} sign_in_clb={sign_in_clb} calendar_clb={calendar_clb} />;
			} else {
				content = (
					<>
						<NavLink title="Calendar" to="/calendar" className={`calendar${page === '/calendar' ? ' active' : ''}`}></NavLink>
						<button
							title="Contact Us"
							className={`contact_us ${modals_context.modal === 'contact_us' && modals_context.opened ? 'active' : ''}`}
							onClick={contact_us_clb}
						>
							?
						</button>
						{page.split('/')[1] !== 'new_booking' ? (
							<NavLink title="Book a Room" to="/new_booking/rooms" className="btn_book">
								Book a Room
							</NavLink>
						) : (
							<></>
						)}
					</>
				);
			}
			break;
		default:
			if (is_mobile) {
				content = <BurgerMenu book_clb={book_clb} contact_us_clb={contact_us_clb} sign_in_clb={sign_in_clb} calendar_clb={calendar_clb} />;
			} else {
				content = (
					<>
						<NavLink title="Calendar" to="/calendar" className={`calendar${page === '/calendar' ? ' active' : ''}`}></NavLink>
						<button
							title="Contact Us"
							className={`contact_us ${modals_context.modal === 'contact_us' && modals_context.opened ? 'active' : ''}`}
							onClick={contact_us_clb}
						>
							?
						</button>
						<a
							title={user ? 'Account' : 'Sign In'}
							href={user ? '/dashboard' : '#!'}
							className={user ? 'registered' : 'sign_in'}
							data-name={user ? initials : ''}
							onClick={user ? () => {} : sign_in_clb}
						>
							{user ? (
								user.photo ? (
									<img src={user.photo} alt="user" className="photo" />
								) : (
									<span data-name={user ? `${user.first_name[0]}${user.last_name[0]}` : ''} />
								)
							) : (
								'Sign In'
							)}
						</a>
						{page.split('/')[1] !== 'new_booking' ? (
							<NavLink title="Book a Room" to="/new_booking/rooms" className="btn_book">
								Book a Room
							</NavLink>
						) : (
							<></>
						)}
					</>
				);
			}
			break;
	}

	return <div className="right">{content}</div>;
};

const Center = ({ page, is_first_page }) => {
	const is_mobile = useContext(IsMobileContext);
	let content;

	if ((is_mobile && is_first_page) || (!is_mobile && (page === '/sign_in' || page === '/create_account'))) {
		content = <img src="/img/logo.png" className="logo" alt="Logo" />;
	}

	return <div className="center">{content}</div>;
};

const BurgerMenu = ({ book_clb, contact_us_clb, calendar_clb, sign_in_clb }) => {
	const [is_open, set_open] = useState(false);
	const { user } = useContext(UserContext);

	const first_name = user ? user.first_name : '';
	const last_name = user ? user.last_name : '';
	const initials = user ? `${first_name[0]}${last_name[0]}` : '';

	const on_click = (callback) => {
		callback();
		set_open(false);
	};

	return (
		<>
			<button className={`burger_button${is_open ? ' active' : ''}`} onClick={() => set_open(!is_open)}>
				<span className="line"></span>
				<span className="line"></span>
				<span className="line"></span>
			</button>
			<div className={`overlay${is_open ? ' open' : ''}`}>
				<nav>
					<h2 className={user ? 'registered' : ''} data-name={user ? initials : ''}>
						{user ? `${first_name} ${last_name}` : 'Guest'}
					</h2>
					<ul className="list">
						<li onClick={() => on_click(book_clb)}>Book Room</li>
						<li onClick={() => on_click(contact_us_clb)}>Contact Us</li>
						<li onClick={() => on_click(calendar_clb)}>Calendar</li>
					</ul>
					{user ? <></> : <div onClick={() => on_click(sign_in_clb)}>Sign In</div>}
				</nav>
			</div>
		</>
	);
};

const Headerbar = ({ page, back_clb, is_first_page, disabled }) => {
	return (
		<div className="headerbar_container">
			<div className="headerbar">
				<Left page={page} back_clb={back_clb} is_first_page={is_first_page} disabled={disabled} />
				<Center page={page} is_first_page={is_first_page} />
				<Right page={page} />
			</div>
		</div>
	);
};

export default Headerbar;
